import { Fragment } from 'react';
import { useSelector } from 'react-redux';
import { Routes, Route } from "react-router-dom";
import TopMenu from '../components/layout/TopMenu';
import BcIntro from '../components/businesscontinuity/BcIntro';
import BcWorkflow from '../components/businesscontinuity/BcWorkflow';
// import { Routes, Route } from "react-router-dom";
import OrganisationSector from '../components/businesscontinuity/organisation/Sector';
import Products from '../components/businesscontinuity/organisation/Products';
import Processes from '../components/businesscontinuity/organisation/Processes'
import BiaIntro from '../components/businesscontinuity/bia/BiaIntro';
import ImpactOrganisation from '../components/businesscontinuity/bia/ImpactOrganisation';
import ImpactGoals from '../components/businesscontinuity/bia/ImpactGoals';
import MaxPeriod from '../components/businesscontinuity/bia/MaxPeriod';
import MaxRecovery from '../components/businesscontinuity/bia/MaxRecovery';
import ProcessPriority from '../components/businesscontinuity/bia/ProcessPriority';

const BusinessContinuity = () => {
    const introDone = useSelector((state) => state.bc.introDone);

    // eigenlijk hier schakelen op basis van de stappen die klaar zijn naar een pagina
    return <Fragment>
        <TopMenu />
        <main>
            { !introDone && <BcIntro /> }
            { introDone && 
            <Routes>
                <Route path="/overview" element={ <BcWorkflow />} />
                <Route path="/organisation/sector" element={<OrganisationSector />} />
                <Route path="/organisation/products" element={<Products />} />
                <Route path="/organisation/processes" element={<Processes />} />
                <Route path="/bia/intro" element={ <BiaIntro /> } />
                <Route path="/bia/impactorganisation" element={ <ImpactOrganisation />} />
                <Route path="/bia/impactgoals" element={ <ImpactGoals />} />
                <Route path="/bia/maxperiod" element={ <MaxPeriod />} />
                <Route path="/bia/maxrecovery" element={ <MaxRecovery />} />
                <Route path="/bia/processpriority" element={ <ProcessPriority /> } />
            </Routes>
             }
        </main>
    </Fragment>
}

export default BusinessContinuity;