import { Table, Icon, Checkbox } from "semantic-ui-react";

const ProcessSelector = (props) => {
    const selectHandler = (event, data) => {
        if(props.onSelect) {
            props.onSelect(props.id, data.checked);
        }
    }

    return (
        <Table.Row positive={props.selected}>            
            <Table.Cell collapsing><Checkbox slider onChange={selectHandler} checked={props.selected} /></Table.Cell>
            <Table.Cell>{props.processName}</Table.Cell>
            <Table.Cell>{props.mtpd}</Table.Cell>
            <Table.Cell>{props.rto}</Table.Cell>
            <Table.Cell collapsing>{props.selected && <Icon color='green' name='checkmark' />}</Table.Cell>
        </Table.Row>
    );
}

export default ProcessSelector;