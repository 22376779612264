import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Segment, Grid, Button, Header, List, Input, Form, Dropdown, Icon, Container } from "semantic-ui-react";
import { useSelector, useDispatch } from 'react-redux';
import { bcOrganisationActions } from '../../../store/bc-organisation-slice';
import { bcActions } from '../../../store/bc-slice';
import { useNavigate } from "react-router-dom";
import DOMPurify from 'dompurify';
import ListItem from './ListItem';

const Processes = () => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const [isManualInput, setIsManualInput] = useState(false);
    const [stdProcess, setStdProcess] = useState();
    const [newProcess, setNewProcess] = useState();
    const navigate = useNavigate();

    const values = useSelector((state) => state.bcOrganisation.steps[2]);

    const allOptions = t('organisationprocesses.processeslist', { returnObjects: true }).map((item) => { return { key: item.id, value: item.id, text: item.name } });
    const selectedProcesses = values.Processes.map(item => {
        if (item.type === "99") {
            return item.otherValue;
        } else {
            return allOptions.find(({ key }) => key === item.type).text;
        }
    });

    const nextStepDisabled = values.Processes.length === 0;

    const products = useSelector((state) => state.bcOrganisation.steps[1].Products);
    let productsHeader = products.map((item, index) => {
        let res = item;
        if (index < (products.length - 2)) {
            res += ", ";
        }
        if (index === (products.length - 2)) {
            res += " & ";
        };
        if (index === (products.length - 1)) {
            res += "!";
        };
        return res;
    });

    const removeProcessHandler = (index) => {
        dispatch(bcOrganisationActions.removeProcess(index));
    };

    const addProcessHandler = (event, data) => {
        event.preventDefault();

        let toBeAdded;
        if (isManualInput) {
            if (!newProcess) {
                return;
            }
            toBeAdded = {
                type: "99",
                otherValue: DOMPurify.sanitize(newProcess)
            };
            setIsManualInput(false);
        } else {
            if (!stdProcess) {
                return;
            }
            toBeAdded = {
                type: stdProcess
            }
        }

        dispatch(bcOrganisationActions.addProcess(toBeAdded));
    };

    const buttonClickHandler = (event, data) => {
        if (!nextStepDisabled) {
            dispatch(bcActions.setProgress({ stepname: "organisatie", progress: 100 }));
            dispatch(bcActions.setResultMessage({ header: t('organisationprocesses.doneheader'), message: t('organisationprocesses.donemessage') }));
            navigate("/overview");
        }
    };

    const dropdownChangeHandler = (event, data) => {
        if (data.value === "99") {
            setIsManualInput(true);
            setStdProcess(null);
        } else {
            setStdProcess(data.value);
        }
    }

    const inputChangeHandler = (event, data) => {
        setNewProcess(data.value);
    }

    const exitManualInput = (event) => {
        setIsManualInput(false);
    }

    let addProcessForm = <Form onSubmit={addProcessHandler}>
        <Container className='refreshable-dropdown'>
            <Button fluid as='div' labelPosition='left'>
                <Dropdown fluid className='left attached refreshable-dropdown' selection placeholder={t('organisationprocesses.standaardplaceholder')} options={allOptions} onChange={dropdownChangeHandler} />
                <Button type='submit' color='teal' icon labelPosition='right'>{t('generic.add')} <Icon name='add' /></Button>
            </Button>
        </Container>
    </Form>

    if (isManualInput) addProcessForm = <Form onSubmit={addProcessHandler}>
        <Input fluid placeholder={t('organisationprocesses.inputplaceholder')} iconPosition='left' icon={<Icon name='delete' link onClick={exitManualInput} />} onChange={inputChangeHandler} action={{ color: "teal", labelPosition: "right", content: t('generic.add'), icon: "add", type: "submit" }} />
    </Form>

    const buttonClickBackHandler = () => {
        navigate("/organisation/products");
    }

    return <Segment style={{ padding: '8em 0em' }} vertical>
        <Grid container stackable verticalAlign='middle'>
            <Grid.Row>
                <Grid.Column width={16}>
                    <Header as='h3' style={{ fontSize: '2em' }}>
                        {productsHeader}</Header>
                    <p style={{ fontSize: '1.33em' }}>
                        {t('organisationprocesses.p1')}
                    </p>
                    <p style={{ fontSize: '1.33em' }}>
                        {t('organisationprocesses.p2')}
                    </p>
                    <p style={{ fontSize: '1.33em' }}>
                        {t('organisationprocesses.p3')}
                    </p>
                </Grid.Column>
            </Grid.Row>
            <Grid.Row>
                <Grid.Column width={8}>
                    <List selection verticalAlign='middle'>
                        {selectedProcesses.map((item, index) => <ListItem name={item} index={index} onDelete={removeProcessHandler} key={index} />)}
                    </List>
                    {addProcessForm}
                </Grid.Column>
            </Grid.Row>
            <Grid.Row>
                <Grid.Column>
                    <Button.Group floated='left'>
                        <Button basic onClick={buttonClickBackHandler}>{t('generic.back')}</Button>
                    </Button.Group>
                    <Button.Group floated='right'>
                        <Button size='huge' onClick={buttonClickHandler} disabled={nextStepDisabled} >{t('generic.next')}</Button>
                    </Button.Group>
                </Grid.Column>
            </Grid.Row>
        </Grid>
    </Segment>
}

export default Processes;