import { Fragment } from "react";
import { useNavigate } from "react-router-dom";
import { useTranslation } from 'react-i18next';
import { useSelector, useDispatch } from 'react-redux';
import { bcBiaActions } from '../../../store/bc-bia-slice';
import { Grid, Container, Header, Button } from "semantic-ui-react";
import SliderCard from "../../UI/slidercard";

const ImpactOrganisation = () => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const values = useSelector((state) => state.bcBia.organisationImpact);

    const buttonClickBackHandler = () => {
        navigate("/bia/intro");
    };

    const buttonClickHandler = () => {
        navigate("/bia/impactgoals");
    };

    const handleSliderChange = (value, slider) => {
        dispatch(bcBiaActions.saveOrganisationImpactValue({name: slider, value: value}));
    }


    return <Fragment><Container text style={{ marginTop: '5em', marginBottom: '2em' }}>
        <Header as='h1'>{t('biaimpactorganisation.header')}</Header>
        <p>{t('biaimpactorganisation.p1')}</p>
        <p>{t('biaimpactorganisation.p2')}</p>
    </Container>
        <Grid container centered stackable>
            <Grid.Column width={10}>
                <SliderCard label={t('biaimpactorganisation.revenue')} labelLow={t('biaimpactorganisation.sliderLow')} labelHigh={t('biaimpactorganisation.sliderHigh')} value={values.Revenue} onChange={(val) => { handleSliderChange(val, 'Revenue') }} />
                <SliderCard label={t('biaimpactorganisation.costs')} labelLow={t('biaimpactorganisation.sliderLow')} labelHigh={t('biaimpactorganisation.sliderHigh')} value={values.Costs} onChange={(val) => { handleSliderChange(val, 'Costs') }} />
                <SliderCard label={t('biaimpactorganisation.reputation')} labelLow={t('biaimpactorganisation.sliderLow')} labelHigh={t('biaimpactorganisation.sliderHigh')} value={values.Reputation} onChange={(val) => { handleSliderChange(val, 'Reputation') }} />
                <SliderCard label={t('biaimpactorganisation.legal')} labelLow={t('biaimpactorganisation.sliderLow')} labelHigh={t('biaimpactorganisation.sliderHigh')} value={values.Legal} onChange={(val) => { handleSliderChange(val, 'Legal') }} />
                <SliderCard label={t('biaimpactorganisation.contracts')} labelLow={t('biaimpactorganisation.sliderLow')} labelHigh={t('biaimpactorganisation.sliderHigh')} value={values.Contracts} onChange={(val) => { handleSliderChange(val, 'Contracts') }} />
            </Grid.Column>
            <Grid.Row>
                <Grid.Column>
                    <Button.Group floated='left'>
                        <Button basic onClick={buttonClickBackHandler}>{t('generic.back')}</Button>
                    </Button.Group>
                    <Button.Group floated='right'>
                        <Button size='huge' onClick={buttonClickHandler} >{t('generic.next')}</Button>
                    </Button.Group>
                </Grid.Column>
            </Grid.Row>
        </Grid>

    </Fragment>;
}

export default ImpactOrganisation;