import { configureStore } from "@reduxjs/toolkit";
import bcSlice from "./bc-slice";

import uiSlice from './ui-slice';
import userSlice from "./user-slice";
import bcOrganisationSlice from "./bc-organisation-slice";
import bcBiaSlice from "./bc-bia-slice";

const store = configureStore({
    reducer: {
        ui: uiSlice.reducer,
        bc: bcSlice.reducer,
        bcOrganisation: bcOrganisationSlice.reducer,
        bcBia: bcBiaSlice.reducer,
        user: userSlice.reducer
    }
});

export default store; 