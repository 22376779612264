import { useTranslation } from 'react-i18next';
import { Segment, Grid, Button, Dropdown } from "semantic-ui-react";
import { useSelector, useDispatch } from 'react-redux';
import { bcOrganisationActions } from '../../../store/bc-organisation-slice';
import { bcActions } from '../../../store/bc-slice';
import { useNavigate } from "react-router-dom";

const OrganisationSector = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const values = useSelector((state) => state.bcOrganisation.steps[0]);
  const nextStepDisabled = !(values.Sector && values.Employees && values.Revenue);

  const sectorlist = t('organisationsector.sectorlist', { returnObjects: true }).map(item => { return { key: item, value: item, text: item } });

  const EmployeesOptions = [{ key: '1', text: '1', value: '1' },
  { key: '9', text: '2-9', value: '9' },
  { key: '49', text: '10-49', value: '49' },
  { key: '99', text: '50-99', value: '99' },
  { key: '249', text: '100-249', value: '249' },
  { key: '250', text: '250+', value: '250' }];

  const RevenueOptions = [
    { key: '699', text: '< 699K​', value: '699' },
    { key: '5m', text: '700K – 5mln​', value: '5m' },
    { key: '25m', text: '5-25 mln​', value: '25m' },
    { key: '50m', text: '25-50 mln​​', value: '50m' },
    { key: '51m', text: '> 50 mln​​', value: '51m' }
  ];

  const buttonClickHandler = () => {
    if (!nextStepDisabled) {
      dispatch(bcActions.setProgress({ stepname: "organisatie", progress: 20 }))
      navigate("/organisation/products");
    }
  }

  const buttonClickBackHandler = () => {
    navigate("/overview");
  }

  const sectorChangeHandler = (event, data) => {
    dispatch(bcOrganisationActions.setStepValues({ step: 0, change: "Sector", value: data.value }));
  }

  const employeesChangeHandler = (event, data) => {
    dispatch(bcOrganisationActions.setStepValues({ step: 0, change: "Employees", value: data.value }));
  }

  const revenueChangeHandler = (event, data) => {
    dispatch(bcOrganisationActions.setStepValues({ step: 0, change: "Revenue", value: data.value }));
  }

  const doneMessage = <Grid.Row>
    <Grid.Column width={16}>
      <p style={{ fontSize: '2em' }}>
        Super. Nu we weten binnen welke sector u actief bent, kunnen wij al een beter inschatting maken van uw risco's.
      </p>
    </Grid.Column>
  </Grid.Row>;

  return <Segment style={{ padding: '8em 0em' }} vertical>
    <Grid container stackable verticalAlign='middle'>
      <Grid.Row>
        <Grid.Column width={16}>
          <p style={{ fontSize: '2em' }}>
            {t('organisationsector.intro')}
          </p>
        </Grid.Column>
      </Grid.Row>
      <Grid.Row>
        <Grid.Column width={5}>
          <Dropdown fluid selection placeholder={t('organisationsector.sectordescription')} options={sectorlist} value={values.Sector} onChange={sectorChangeHandler} />
        </Grid.Column>
        <Grid.Column width={5}>
          <Dropdown fluid selection placeholder={t('organisationsector.employeesdescription')} options={EmployeesOptions} value={values.Employees} onChange={employeesChangeHandler} />
        </Grid.Column>
        <Grid.Column width={5}>
          <Dropdown fluid selection placeholder={t('organisationsector.revenuedescription')} options={RevenueOptions} value={values.Revenue} onChange={revenueChangeHandler} />
        </Grid.Column>
      </Grid.Row>
      {!nextStepDisabled && doneMessage}
      <Grid.Row>
        <Grid.Column>
          <Button.Group floated='left'>
            <Button basic onClick={buttonClickBackHandler}>{t('generic.back')}</Button>
          </Button.Group>
          <Button.Group floated='right'>
            <Button size='huge' onClick={buttonClickHandler} disabled={nextStepDisabled} >{t('generic.next')}</Button>
          </Button.Group>
        </Grid.Column>
      </Grid.Row>
    </Grid>
  </Segment>;

}

export default OrganisationSector;