import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useNavigate } from "react-router-dom";
import { Segment, Grid, Header, Image, Button } from "semantic-ui-react";
import { bcActions } from '../../../store/bc-slice';

const BiaIntro = () => {
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const navigate = useNavigate();

    const buttonClickHandler = () => {
        dispatch(bcActions.setProgress({ stepname: "bia", progress: 20 }))
        navigate("/bia/impactorganisation");      
    }

    return    <Segment style={{ padding: '8em 0em' }} vertical>
    <Grid container stackable verticalAlign='middle'>
      <Grid.Row>
        <Grid.Column width={8}>
          <Header as='h3' style={{ fontSize: '2em' }}>
          {t('biaintro.header')}
          </Header>
          <p style={{ fontSize: '1.33em' }}>
          ​{t('biaintro.p1')}
          </p>
          <p style={{ fontSize: '1.33em' }}>
          {t('biaintro.p2')}
          </p>
        </Grid.Column>
        <Grid.Column floated='right' width={6}>
          <Image bordered rounded size='large' src='https://react.semantic-ui.com/images/wireframe/white-image.png' />
        </Grid.Column>
      </Grid.Row>
      <Grid.Row>
        <Grid.Column textAlign='center'>
          <Button size='huge' onClick={buttonClickHandler}>{t('generic.next')}</Button>
        </Grid.Column>
      </Grid.Row>
    </Grid>
  </Segment>
}

export default BiaIntro;