import { createSlice } from "@reduxjs/toolkit";

const bcOrganisationSlice = createSlice({
    name: "bcOrganisation",
    initialState: {
        currentstep: 0,
        steps: [
            {
                // 0: Sector
                Sector: null,
                Employees: null,
                Revenue: null
            },
            {
                // 1: Products
                Products: []
            },
            {
                // 2: Processen
                Processes: []
            }
        ]
    },

    reducers: {
        setStepValues(state, action) {
            let newState = state.steps[action.payload.step];
            newState[action.payload.change] = action.payload.value;
            state.steps[action.payload.step] = newState;
        },
        addProduct(state, action) {
            state.steps[1].Products.push(action.payload);
        },
        removeProduct(state, action) {
            state.steps[1].Products.splice(action.payload, 1);
        },
        addProcess(state, action) {
            if ((action.payload.type === "99") || (!state.steps[2].Processes.find(item => item.type === action.payload.type))) {
                state.steps[2].Processes.push(action.payload);
            }
        },
        removeProcess(state, action) {
            state.steps[2].Processes.splice(action.payload, 1);
        }
    }
});

export const bcOrganisationActions = bcOrganisationSlice.actions;

export default bcOrganisationSlice;