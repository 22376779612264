import { createSlice } from "@reduxjs/toolkit";

const bcSlice = createSlice({
    name: "bc",
    initialState: {
        introDone: false,
        showMessage: null,
        steps: [
            {
                name: "organisation",
                enabled: true,
                visable: true,
                progress: 0,
                link: "/organisation/sector"
            },
            {
                name: "bia",
                enabled: true,
                visable: true,
                progress: 0,
                link: "/bia/intro"
            },
            {
                name: "riskassesment",
                enabled: false,
                visable: true,
                progress: 0,
                link: "/"
            }]
    },

    reducers: {
        toggleStep(state, action) {
            switch (action.payload.stepname) {
                case "organisatie":
                    state.steps[0].enabled = !state.steps[0].enabled;
                    break;
                case "bia":
                    state.steps[1].enabled = !state.steps[1].enabled;
                    break;
                case "riskassesment":
                    state.steps[2].enabled = !state.steps[2].enabled;
                    break;
            }
        },
        setProgress(state, action) {
            switch (action.payload.stepname) {
                case "organisatie":
                    state.steps[0].progress = action.payload.progress;
                    break;
                case "bia":
                    state.steps[1].progress = action.payload.progress;
                    break;
                case "riskassesment":
                    state.steps[2].progress = action.payload.progress;
                    break;
            }

        },
        setResultMessage(state, action) {
            state.showMessage = action.payload;
        },
        toggleIntroDone(state) {
            state.introDone = !state.introDone;
        }
    }
});

export const bcActions = bcSlice.actions;

export default bcSlice;