import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import { Suspense } from 'react';
import './i18n';
import { BrowserRouter } from "react-router-dom";
import { Provider } from 'react-redux';
import store from './store';


import 'semantic-ui-css/semantic.min.css'
import Authorization from './components/auth/Authorization';


ReactDOM.render(
  <React.StrictMode>
    <Suspense fallback={<div align="center">...loading files</div>}>
    <Provider store={store}>
      <Authorization>
        <BrowserRouter>
          <App />
        </BrowserRouter>
      </Authorization>
      </Provider>
    </Suspense>
  </React.StrictMode>,
  document.getElementById('root')
);
