import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { userActions } from '../../store/user-slice';

const calculateRemainingTime = (expirationTime) => {
    if (!expirationTime) {
        return 0;
    }
    const currentTime = new Date().getTime();
    const adjExpirationTime = new Date(expirationTime).getTime();

    const remainingDuration = adjExpirationTime - currentTime;

    return remainingDuration;
};

const retrieveStoredToken = () => {
    const storedToken = localStorage.getItem("token");
    const storedId = localStorage.getItem("id");
    const storedEmail = localStorage.getItem("email");
    const storedExpirationTimeout = localStorage.getItem("exptime");

    const remainingTime = calculateRemainingTime(storedExpirationTimeout);

    if (remainingTime <= 60000) {
        localStorage.removeItem("id");
        localStorage.removeItem("email");
        localStorage.removeItem("token");
        localStorage.removeItem("exptime");
        return null;
    }

    return { id: storedId, email: storedEmail, token: storedToken, exptime: storedExpirationTimeout, duration: remainingTime };
};

const Authorization = (props) => {
    const dispatch = useDispatch();
//    const token = useSelector((state) => state.user.token);

    // fetch token from local storage at reloads
    useEffect(() => {
        const tokenData = retrieveStoredToken();

        if (tokenData) {
            dispatch(userActions.login({ id: tokenData.id, email: tokenData.email, token: tokenData.token, expirationTime: tokenData.exptime }));
        }
    }, [dispatch]);

    // when token changed set timer
    // useEffect((

    // ) => {}, [token]);

    return props.children;
};

export default Authorization;