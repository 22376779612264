import { createSlice } from "@reduxjs/toolkit";

const userSlice = createSlice({
    name: "user",
    initialState: {
        id: "",
        email: "",
        token: "",
        expirationTime: null,
        isLoggedIn: false,
    },
    reducers: {
        login(state, action) {
            if (action.payload.token) {
                state.id = action.payload.id;
                state.email = action.payload.email;
                state.token = action.payload.token;
                state.expirationTime = action.payload.expirationTime;
                state.isLoggedIn = true;
                localStorage.setItem("id", state.id);
                localStorage.setItem("email", state.email);
                localStorage.setItem("token", state.token);
                localStorage.setItem("exptime", state.expirationTime);
            }
        },
        logout(state) {
            state.token = "";
            state.expirationTime = null;
            state.isLoggedIn = false;
            localStorage.removeItem("id");
            localStorage.removeItem("email");
            localStorage.removeItem("token");
            localStorage.removeItem("exptime");
        }

    },
});

export const userActions = userSlice.actions;

export default userSlice;