import { createSlice } from "@reduxjs/toolkit";

const bcBiaSlice = createSlice({
    name: "bcBia",
    initialState: {
        organisationImpact: {
            Revenue: 0,
            Costs: 0,
            Reputation: 0,
            Legal: 0,
            Contracts: 0
        },
        goalsImpact: {
            Growth: 0,
            Innovation: 0,
            Sustainability: 0,
            CustomerSatisfaction: 0,
            Employership: 0,
            Custom: []
        },
        mtpds: [],
        rtos: [],
        prioritizedProcesses: []
    },

    reducers: {
        saveOrganisationImpactValue(state, action) {
            state.organisationImpact[action.payload.name] = action.payload.value;
        },
        saveGoalsImpactValue(state, action) {
            state.goalsImpact[action.payload.name] = action.payload.value;
        },
        saveCustomGoalsImpactValue(state, action) {
            const goalIndex = state.goalsImpact.Custom.findIndex( item => item.name === action.payload.name );
            if (goalIndex >= 0) {
                state.goalsImpact.Custom[goalIndex].value = action.payload.value;
            }
        },
        addGoalsImpactCustomGoal(state, action) {
            state.goalsImpact.Custom.push({ name: action.payload, value: 0 });
        },
        deleteGoalsImpactCustomGoal(state, action) {
            state.goalsImpact.Custom = state.goalsImpact.Custom.filter( item => item.name !== action.payload ); 
        },
        saveMtpd(state, action) {
            const mtpdIndex = state.mtpds.findIndex(item => item.id === action.payload.id);
            if(mtpdIndex === -1) {
                state.mtpds.push(action.payload);
            } else {
                if(!action.payload.value) {
                    state.mtpds.splice(mtpdIndex, 1);
                } else {
                    state.mtpds[mtpdIndex] = action.payload;
                }
            };
        },
        saveRto(state, action) {
            const rtoIndex = state.rtos.findIndex(item => item.id === action.payload.id);
            if(rtoIndex === -1) {
                state.rtos.push(action.payload);
            } else {
                if(!action.payload.value) {
                    state.rtos.splice(rtoIndex, 1);
                } else {
                    state.rtos[rtoIndex] = action.payload;
                }
            };
        },
        addPrioritizedProcess(state, action) {
            state.prioritizedProcesses.push(action.payload);
        },
        deletePrioritizedProcess(state, action) {
            state.prioritizedProcesses = state.prioritizedProcesses.filter( item => item !== action.payload ); 
        },
    }
});

export const bcBiaActions = bcBiaSlice.actions;

export default bcBiaSlice;