import { Card, Image, Grid, Progress, Dimmer } from "semantic-ui-react";
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import classes from "./WorkflowStep.module.css";

const WorkflowStep = (props) => {
    const { t } = useTranslation();

    if(!props.item.visable) {   
        return null;        
    }

    const card =<Dimmer.Dimmable as={Card} dimmed={!props.item.enabled} >
    <Image src='https://react.semantic-ui.com/images/avatar/large/matthew.png' wrapped ui={false} />
    <Card.Content>
        <Card.Header>{t(props.item.name + 'card.header')}</Card.Header>
        <Card.Meta>
            <span className='date'>{t(props.item.name + 'card.subheader')}</span>
        </Card.Meta>
        <Card.Description>
            {t(props.item.name + 'card.description')}
        </Card.Description>
    </Card.Content>
    <Card.Content extra>
        <Progress percent={props.item.progress} />
    </Card.Content>
    <Dimmer active={!props.item.enabled} inverted />
</Dimmer.Dimmable>;

    return <Grid.Column className={classes.workflowgrid}>
                    {props.item.enabled && <Link to={props.item.link}>{card}</Link>}
                    {!props.item.enabled && card}
                </Grid.Column>
}

export default WorkflowStep;