import { Fragment } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Grid, Message, Container } from "semantic-ui-react";
import WorkflowStep from './WorkflowStep';
import { bcActions } from '../../store/bc-slice';

import classes from "./BcWorkflow.module.css"

const BcWorkflow = () => {
    const bcstate = useSelector((state) => state.bc);
    const dispatch = useDispatch();

    let resultMessageHeader = "";
    let resultMessage = "";
    let timer;

    const handleDismiss = () => {
        clearTimeout(timer);
        dispatch(bcActions.setResultMessage(null));
    };

    if (bcstate.showMessage !== null) {
        resultMessageHeader = bcstate.showMessage.header;
        resultMessage = bcstate.showMessage.message;
        timer = setTimeout(handleDismiss, 5000);
    };

    const message = <Container className={classes.message}><Message positive onDismiss={handleDismiss}>
        <Message.Header>{resultMessageHeader}</Message.Header>
        <p>{resultMessage}</p>
    </Message></Container>;

    return <Fragment>
        {bcstate.showMessage && message}
        <Grid container columns={4} stackable>
            {
                bcstate.steps.map((item, index) => <WorkflowStep item={item} key={index} />)
            }
        </Grid>
    </Fragment>
} 
        
export default BcWorkflow;