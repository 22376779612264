import { Fragment } from 'react';
import AuthPage from './pages/AuthPage';
import { useSelector } from 'react-redux';

import './App.css';
import BusinessContinuity from './pages/BusinessContinuity';

function App() {
  const isLoggedIn = useSelector((state) => state.user.isLoggedIn);

  return (
    <Fragment>
      {!isLoggedIn && <AuthPage />}
      {isLoggedIn && <BusinessContinuity />}
    </Fragment>
  );
}

export default App;
