import { createSlice } from "@reduxjs/toolkit";

const uiSlice = createSlice({
  name: "ui",
  initialState: {
    userIsLoggedIn: false,
  },
  reducers: {
    userLogin(state) {
        state.userIsLoggedIn = true;
    },
    userLogout(state) {
        state.userIsLoggedIn = false;
    }

  },
});

export const uiActions = uiSlice.actions;

export default uiSlice;