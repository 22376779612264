import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Grid, Header, Form, Segment, Button, Message } from "semantic-ui-react";
import { useDispatch } from 'react-redux';
import { userActions } from '../../store/user-slice';
import DOMPurify from 'dompurify';

import classes from "./AuthForm.module.css";

function validateEmail(email) {
    const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(email);
}

function validatePasswd(passwd) {
    return (passwd.length > 5);
}

const AuthForm = () => {
    const { t } = useTranslation();
    const [isLogin, setIsLogin] = useState(true);
    const dispatch = useDispatch();

    const [emailError, setEmailError] = useState(false);
    const [passwdError, setPasswdError] = useState(false);

    const switchAuthModeHandler = () => {
        setIsLogin((prevState) => !prevState);
    };

    const submitHandler = (event) => {
        event.preventDefault();

        const enteredEmail = DOMPurify.sanitize(event.target["email"].value);
        const enteredPassword = DOMPurify.sanitize(event.target["password"].value);

        if (!validateEmail(enteredEmail)) {
            setEmailError(true);
            return;
        }
        if (!validatePasswd(enteredPassword)) {
            setPasswdError(true);
            return;
        }

        let url;
        if (isLogin) {
            url =
                "https://identitytoolkit.googleapis.com/v1/accounts:signInWithPassword?key=AIzaSyB5rQ7ealv8KrOkUTB0M4rdbEpzid08i5U";
        } else {
            url =
                "https://identitytoolkit.googleapis.com/v1/accounts:signUp?key=AIzaSyB5rQ7ealv8KrOkUTB0M4rdbEpzid08i5U";
        }

        
        fetch(url, {
            method: "POST",
            body: JSON.stringify({
                email: enteredEmail,
                password: enteredPassword,
                returnSecureToken: true,
            }),
            headers: {
                "Content-Type": "application/json",
            },
        })
            .then((res) => {
                if (res.ok) {                    
                    return res.json();                    
                } else {
                    res.json().then((data) => {
                        let errorMessage = "Authentication failed";
                        if (data && data.error && data.error.message) {
                            errorMessage = data.error.message;
                        }
                        throw new Error(errorMessage);
                    }).catch((err) => {
                        alert(err.message);
                        return;
                    });
                }
            })
            .then((data) => {
                if (data && data.expiresIn) {
                    const expirationTime = new Date(
                        new Date().getTime() + +data.expiresIn * 1000
                    );

                    dispatch(userActions.login({ id: data.localId, email: data.email, token: data.idToken, expirationTime: expirationTime.toISOString() }));
                }
            })
            .catch((err) => {
                alert(err.message);
                return;
            });
    };

    const emailChangeHandler = (event) => {
        if (emailError) {
            if (validateEmail(event.target.value)) {
                setEmailError(false);
            }
        }
    }

    const passwdChangeHandler = (event) => {
        if (passwdError) {
            if (validatePasswd(event.target.value)) {
                setPasswdError(false);
            }
        }
    }

    return (<Segment><Grid textAlign='center' style={{ height: '80vh' }} verticalAlign='middle'>
        <Grid.Column style={{ maxWidth: 450 }}>
            <Header as='h2' color='teal' textAlign='left'>
                {isLogin ? t('userauthentication.header') : t('userauthentication.signupheader')}
                {emailError}
            </Header>
            <Form size='large' onSubmit={submitHandler}>
                <Segment stacked>
                    <Form.Input fluid icon='user' iconPosition='left' placeholder={t('userauthentication.emailaddress')} id='email' error={emailError ? { content: t('userauthentication.emailerror') } : false} onChange={emailChangeHandler} />
                    <Form.Input
                        fluid
                        icon='lock'
                        iconPosition='left'
                        placeholder={t('userauthentication.password')}
                        type='password'
                        id='password'
                        error={passwdError ? { content: t('userauthentication.passwderror') } : false}
                        onChange={passwdChangeHandler}
                    />
                    <Button color='teal' fluid size='large'>
                        {isLogin ? t('userauthentication.btnlogin') : t('userauthentication.btnsignup')}
                    </Button>
                </Segment>
            </Form>
            <Message>
                {isLogin ? t('userauthentication.newtoapp') : ""} <button className={classes.linkButton} onClick={switchAuthModeHandler}>{isLogin ? t('userauthentication.signuplink') : t('userauthentication.loginlink')}</button>
            </Message>
        </Grid.Column>
    </Grid></Segment>);
}

export default AuthForm;