import { Slider } from "react-semantic-ui-range";
import { Segment, Label, Icon } from "semantic-ui-react";

import classes from "./slidercard.module.css";

const SliderCard = (props) => {
    const settings = {
        start: 0,
        min: 0,
        max: 4,
        step: 1
    };

    if(props.value) {
        settings.start = props.value;
    }

    if(props.onChange) {
        settings.onChange = props.onChange;
    }

    return <Segment className={classes.segment}>
        <Label attached='top left'>{props.label}</Label>
        <Slider discrete color="red" settings={settings} />
        { props.onDelete && <Label attached='top right'><Icon name="delete" onClick={props.onDelete} /></Label> }
        <Label basic pointing attached='bottom left'>{props.labelLow}</Label>
        <Label basic pointing attached='bottom right'>{props.labelHigh}</Label>
    </Segment>;
}

export default SliderCard;