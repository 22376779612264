import { Fragment } from "react";
import { useNavigate } from "react-router-dom";
import { useTranslation } from 'react-i18next';
import { useSelector, useDispatch } from 'react-redux';
import { bcBiaActions } from '../../../store/bc-bia-slice';
import { Grid, Container, Header, Button, Table } from "semantic-ui-react";
import ProcessSelector from "./ProcessSelector";

const rtoTimeSorter = (a, b) => {
    const numA = Number(a.value);
    const numB = Number(b.value);
    if (numA < numB) return -1;
    if (numA > numB) return 1;
    return 0;
};

const ProcessPriority = () => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const mtpds = useSelector((state) => state.bcBia.mtpds);
    const rtos = useSelector((state) => state.bcBia.rtos);
    const prioritizedProcesses = useSelector((state) => state.bcBia.prioritizedProcesses);

    const maxRecoveryTimeOptions = t('biamaxrecovery.timeOptions', { returnObjects: true })
    const maxPeriodTimeOptions = t('biamaxperiod.timeOptions', { returnObjects: true })

    const defaultProcesses = t('organisationprocesses.processeslist', { returnObjects: true })
    const getProcessNameFromRtoId = (id) => {
        const process = defaultProcesses.find(item => item.id === id)
        if (!process) {
            return id;
        } else {
            return process.name
        }
    }
    const getMtpdNameFromRtoId = (id) => {
        const mtpd = mtpds.find(item => item.id === id);
        return maxPeriodTimeOptions.find(option => option.id === mtpd.value).name;
    }

    const buttonClickBackHandler = () => {
        navigate("/bia/maxrecovery");
    };

    const buttonClickHandler = () => {
        navigate("/bia/processpriority");
    };

    const selectProcessHandler = (id, selected) => {
        if (selected) {
            dispatch(bcBiaActions.addPrioritizedProcess(id));
        } else {
            dispatch(bcBiaActions.deletePrioritizedProcess(id));
        }
    }

    return <Fragment>
        <Container text style={{ marginTop: '5em', marginBottom: '2em' }}>
            <Header as='h1'>{t('biaprocesspriority.header')}</Header>
            <p>{t('biaprocesspriority.p1')}</p>
            <p>{t('biaprocesspriority.p2')}</p>
        </Container>
        <Container text>
            <Table>
                <Table.Header fullWidth>
                    <Table.HeaderCell></Table.HeaderCell>
                    <Table.HeaderCell>{t('biaprocesspriority.process')}</Table.HeaderCell>
                    <Table.HeaderCell>{t('biaprocesspriority.mtpd')}</Table.HeaderCell>
                    <Table.HeaderCell>{t('biaprocesspriority.rto')}</Table.HeaderCell>
                    <Table.HeaderCell>{t('biaprocesspriority.bcp')}</Table.HeaderCell>
                </Table.Header>
                <Table.Body>
                    {rtos.slice().sort(rtoTimeSorter).map(rto => <ProcessSelector
                        processName={getProcessNameFromRtoId(rto.id)}
                        mtpd={getMtpdNameFromRtoId(rto.id)}
                        rto={maxRecoveryTimeOptions.find(item => item.id === rto.value).name}
                        selected={prioritizedProcesses.find(item => item === rto.id)}
                        id={rto.id}
                        onSelect={selectProcessHandler} />)}
                </Table.Body>
            </Table>
        </Container>
        <Grid container centered stackable style={{ marginTop: '2em' }}>
            <Grid.Row>
                <Grid.Column>
                    <Button.Group floated='left'>
                        <Button basic onClick={buttonClickBackHandler}>{t('generic.back')}</Button>
                    </Button.Group>
                    <Button.Group floated='right'>
                        <Button size='huge' onClick={buttonClickHandler} >{t('generic.next')}</Button>
                    </Button.Group>
                </Grid.Column>
            </Grid.Row>
        </Grid>
    </Fragment >
}

export default ProcessPriority;