import { Button, List, Icon } from "semantic-ui-react";

const ListItem = (props) => {
    const onClickHandler = () => {
        props.onDelete(props.index);
    }

    return <List.Item>
        <List.Content floated='right'>
            <Button size="mini" onClick={onClickHandler}><Icon name="delete" /></Button>
        </List.Content>
        <List.Content>
            <List.Header>{props.name}</List.Header>
        </List.Content>
    </List.Item>
}

export default ListItem;