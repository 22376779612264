import { useTranslation } from 'react-i18next';
import { Grid, Button, Segment } from "semantic-ui-react";

const TimePicker = (props) => {
    const { t } = useTranslation();

    const buttonClickHandler = (newValue) => {
        if (props.onAdd) {
            props.onAdd(props.id, newValue);
        }
    }

    const changeLinkClickHandler = () => {
        if (props.onAdd) {
            props.onAdd(props.id, null);
        }
    }

    let selectOptions;
    if(props.max) {
        selectOptions = props.options.filter(item => Number(item.id) < (Number(props.max) + 1));
    } else {
        selectOptions = props.options;
    }    

    let output;
    if (!props.value) {
        output =
            <Grid.Row>
                <Grid.Column width={12}>
                    <Segment>{props.question}</Segment>
                </Grid.Column>
                <Grid.Column width={12}>
                    <Segment>
                        {selectOptions.map(item => <Button onClick={() => { buttonClickHandler(item.id) }}>{item.name}</Button>)}
                    </Segment>
                </Grid.Column>
            </Grid.Row>;
    } else {
        output =
            <Grid.Row>
                <Grid.Column width={2}>
                    <div><b>{props.name}</b></div>
                </Grid.Column>
                <Grid.Column width={6}>
                    <div>{props.preValuetext} <b>{props.options.find(item => item.id === props.value).name}</b> <a href="#" onClick={changeLinkClickHandler}>{t('generic.change')}</a></div>
                </Grid.Column>
            </Grid.Row>;
    }

    return output;
}

export default TimePicker;