import { useState } from 'react';
import { Container, Menu, Dropdown, Icon } from "semantic-ui-react";
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { userActions } from '../../store/user-slice';
import { useNavigate } from "react-router-dom";

const TopMenu = () => {
    const [lang, setLang] = useState('NL');
    const { i18n } = useTranslation();
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const onLangChangeHandler = (event, { value }) => {
        setLang(value);
        i18n.changeLanguage(value.toLowerCase());
    }

    const logoutHandler = () => {
        dispatch(userActions.logout());
    };

    const homeClickedHandler = () => {
        navigate("/overview");
    }

    return (
        <header>
            <Menu fixed='top' inverted>
                <Container>
                    <Menu.Item as='a' header onClick={homeClickedHandler}>
                        168 Data - Business Continuity
                    </Menu.Item>
                    <Menu.Item as='a' position="right">
                        <Icon name="log out" onClick={logoutHandler}></Icon>
                    </Menu.Item>
                    <Dropdown item text={lang}>
                        <Dropdown.Menu>
                            <Dropdown.Item value="NL" onClick={onLangChangeHandler}>NL</Dropdown.Item>
                            <Dropdown.Item value="EN" onClick={onLangChangeHandler}>EN</Dropdown.Item>
                        </Dropdown.Menu>
                    </Dropdown>
                </Container>
            </Menu>
        </header>
    )
}

export default TopMenu;