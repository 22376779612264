import { Fragment } from "react";
import { useNavigate } from "react-router-dom";
import { useTranslation } from 'react-i18next';
import { useSelector, useDispatch } from 'react-redux';
import { bcBiaActions } from '../../../store/bc-bia-slice';
import { Grid, Container, Header, Button } from "semantic-ui-react";
import TimePicker from "../../UI/TimePicker";

const MaxPeriod = () => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const processes = useSelector((state) => state.bcOrganisation.steps[2].Processes);

    const mtpds = useSelector((state) => state.bcBia.mtpds);
    const getMtpd = (processtype, processOtherValue) => {
        let mtpdVal;
        if (processtype === "99") {
            mtpdVal = mtpds.find(item => item.id === processOtherValue);
        } else {
            mtpdVal = mtpds.find(item => item.id === processtype);
        }
        return mtpdVal;
    }

    const defaultProcesses = t('organisationprocesses.processeslist', { returnObjects: true })
    const getProcessName = (processtype, processOtherValue) => {
        if (processtype === "99") {
            return processOtherValue;
        } else {
            return defaultProcesses.find(item => item.id === processtype).name
        }
    }
    const getProcessId = (processtype, processOtherValue) => {
        if (processtype === "99") {
            return processOtherValue;
        } else {
            return processtype;
        }
    }

    const timeOptions = t('biamaxperiod.timeOptions', { returnObjects: true })

    const buttonClickBackHandler = () => {
        navigate("/bia/impactgoals");
    };

    const buttonClickHandler = () => {
        navigate("/bia/maxrecovery");
    };

    const addMtpdHandler = (type, value) => {
        dispatch(bcBiaActions.saveMtpd({ id: type, value: value }));
    }

    let firstToBeEditedFound = false;

    return <Fragment><Container text style={{ marginTop: '5em', marginBottom: '2em' }}>
        <Header as='h1'>{t('biamaxperiod.header')}</Header>
        <p>{t('biamaxperiod.p1')}</p>
    </Container>
        <Grid container centered stackable>
            {processes.map((process) => {
                const mtpd = getMtpd(process.type, process.otherValue);
                const processName = getProcessName(process.type, process.otherValue);
                const processId = getProcessId(process.type, process.otherValue);
                if (firstToBeEditedFound) {
                    return null;
                } else {
                    if (!mtpd) {
                        firstToBeEditedFound = true;
                        return <TimePicker name={processName} id={processId} options={timeOptions} onAdd={addMtpdHandler} key={processId} question={t('biamaxperiod.question', { processName: processName})} />
                    } else {
                        return <TimePicker name={processName} id={processId} options={timeOptions} value={mtpd.value} onAdd={addMtpdHandler} key={processId} preValuetext="maximaal" question={t('biamaxperiod.question', { processName: processName})} />
                    }
                };
            })}

            <Grid.Row>
                <Grid.Column>
                    <Button.Group floated='left'>
                        <Button basic onClick={buttonClickBackHandler}>{t('generic.back')}</Button>
                    </Button.Group>
                    <Button.Group floated='right'>
                        <Button size='huge' onClick={buttonClickHandler} disabled={processes.length>mtpds.length} >{t('generic.next')}</Button>
                    </Button.Group>
                </Grid.Column>
            </Grid.Row>
        </Grid>
    </Fragment>;
}

export default MaxPeriod;