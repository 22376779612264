import { Fragment, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useTranslation } from 'react-i18next';
import { useSelector, useDispatch } from 'react-redux';
import { bcBiaActions } from '../../../store/bc-bia-slice';
import { Grid, Container, Header, Button, Modal, Form, Input } from "semantic-ui-react";
import SliderCard from "../../UI/slidercard";

const ImpactGoals = () => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [extragoalOpen, setExtragoalOpen] = useState(false)

    const values = useSelector((state) => state.bcBia.goalsImpact);

    const buttonClickBackHandler = () => {
        navigate("/bia/impactorganisation");
    };

    const buttonClickHandler = () => {
        navigate("/bia/maxperiod");
    };

    const handleSliderChange = (value, slider) => {
        dispatch(bcBiaActions.saveGoalsImpactValue({ name: slider, value: value }));
    }

    const handleCustomSliderChange = (value, slider) => {
        dispatch(bcBiaActions.saveCustomGoalsImpactValue({ name: slider, value: value }));
    }

    const handleCustomSliderDelete = (slider) => {
        dispatch(bcBiaActions.deleteGoalsImpactCustomGoal(slider));
    }

    const handleExtraGoalButtonClicked = () => {
        setExtragoalOpen(true);
    }

    const handleExtraGoalSubmit = (event, data) => {
        const newGoal = event.target["newGoal"].value;
        if(newGoal) {
            dispatch(bcBiaActions.addGoalsImpactCustomGoal(newGoal));
        }
        setExtragoalOpen(false);
    }

    return <Fragment><Container text style={{ marginTop: '5em', marginBottom: '2em' }}>
        <Header as='h1'>{t('biaimpactgoals.header')}</Header>
        <p>{t('biaimpactgoals.p1')}</p>
        <p>{t('biaimpactgoals.p2')}</p>
    </Container>
        <Grid container centered stackable>
            <Grid.Column width={10}>
                <SliderCard label={t('biaimpactgoals.growth')} labelLow={t('biaimpactorganisation.sliderLow')} labelHigh={t('biaimpactorganisation.sliderHigh')} value={values.Growth} onChange={(val) => { handleSliderChange(val, 'Growth') }} />
                <SliderCard label={t('biaimpactgoals.innovation')} labelLow={t('biaimpactorganisation.sliderLow')} labelHigh={t('biaimpactorganisation.sliderHigh')} value={values.Innovation} onChange={(val) => { handleSliderChange(val, 'Innovation') }} />
                <SliderCard label={t('biaimpactgoals.sustainability')} labelLow={t('biaimpactorganisation.sliderLow')} labelHigh={t('biaimpactorganisation.sliderHigh')} value={values.Sustainability} onChange={(val) => { handleSliderChange(val, 'Sustainability') }} />
                <SliderCard label={t('biaimpactgoals.customerSatisfaction')} labelLow={t('biaimpactorganisation.sliderLow')} labelHigh={t('biaimpactorganisation.sliderHigh')} value={values.CustomerSatisfaction} onChange={(val) => { handleSliderChange(val, 'CustomerSatisfaction') }} />
                <SliderCard label={t('biaimpactgoals.employership')} labelLow={t('biaimpactorganisation.sliderLow')} labelHigh={t('biaimpactorganisation.sliderHigh')} value={values.Employership} onChange={(val) => { handleSliderChange(val, 'Employership') }} />
                {values.Custom.map((item, index) => {
                    return <SliderCard label={item.name} labelLow={t('biaimpactorganisation.sliderLow')} labelHigh={t('biaimpactorganisation.sliderHigh')} value={item.value} onChange={(val) => { handleCustomSliderChange(val, item.name) }} key={index} onDelete={() => handleCustomSliderDelete(item.name)} />
                })}
                <Button onClick={handleExtraGoalButtonClicked}>{t('biaimpactgoals.addExtraGoal')}</Button>
            </Grid.Column>
            <Grid.Row>
                <Grid.Column>
                    <Button.Group floated='left'>
                        <Button basic onClick={buttonClickBackHandler}>{t('generic.back')}</Button>
                    </Button.Group>
                    <Button.Group floated='right'>
                        <Button size='huge' onClick={buttonClickHandler} >{t('generic.next')}</Button>
                    </Button.Group>
                </Grid.Column>
            </Grid.Row>
        </Grid>
        <Modal as={Form} onSubmit={handleExtraGoalSubmit} onClose={() => setExtragoalOpen(false)} open={extragoalOpen}>
            <Modal.Content>
                <Modal.Description>
                    <Header>{t('biaimpactgoals.addExtraGoal')}</Header>
                    <Input placeholder={t('biaimpactgoals.extraGoalPlaceholder')} id="newGoal" />
                </Modal.Description>
            </Modal.Content>
            <Modal.Actions>
                <Button onClick={() => setExtragoalOpen(false)} content={t('generic.cancel')} />
                <Button type="submit" content={t('generic.add')}/>
            </Modal.Actions>
        </Modal>
    </Fragment>;
}

export default ImpactGoals;