import { useTranslation } from 'react-i18next';
import { Segment, Grid, Button, Header, List, Input, Form, Container } from "semantic-ui-react";
import { useSelector, useDispatch } from 'react-redux';
import { bcOrganisationActions } from '../../../store/bc-organisation-slice';
import { bcActions } from '../../../store/bc-slice';
import { useNavigate } from "react-router-dom";
import DOMPurify from 'dompurify';
import ListItem from './ListItem';

const Products = () => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const values = useSelector((state) => state.bcOrganisation.steps[1]);

    const nextStepDisabled = values.Products.length === 0;

    const removeProductHandler = (index) => {
        dispatch(bcOrganisationActions.removeProduct(index));
    };

    const addProductHandler = (event, data) => {
        event.preventDefault();
        const newProduct = event.target["newProduct"].value;

        if (newProduct) {
            dispatch(bcOrganisationActions.addProduct(DOMPurify.sanitize(newProduct)));
            event.target["newProduct"].value = "";
        }
    };

    const buttonClickHandler = (event, data) => {
        if (!nextStepDisabled) {
            dispatch(bcActions.setProgress({ stepname: "organisatie", progress: 60 }))
            navigate("/organisation/processes");
        }
    };

    const buttonClickBackHandler = () => {
        navigate("/organisation/sector");
    }

    return <Segment style={{ padding: '8em 0em' }} vertical>
        <Grid container stackable verticalAlign='middle'>
            <Grid.Row>
                <Grid.Column width={16}>
                    <Header as='h3' style={{ fontSize: '2em' }}>
                        {t('organisationproducts.header')}</Header>
                    <p style={{ fontSize: '1.33em' }}>
                        {t('organisationproducts.p1')}
                    </p>
                </Grid.Column>
            </Grid.Row>
            <Grid.Row>
                <Grid.Column width={8}>
                    <List selection verticalAlign='middle'>
                        {values.Products.map((item, index) => <ListItem name={item} index={index} onDelete={removeProductHandler} key={index} />)}
                    </List>
                    <Form onSubmit={addProductHandler}>
                        <Input fluid placeholder={t('organisationproducts.productplaceholder')} id='newProduct' disabled={values.Products.length > 4} action={{color: "teal", labelPosition: "right", content: t('generic.add'), icon: "add", type: "submit"}}/>
                    </Form>
                </Grid.Column>
            </Grid.Row>
            <Grid.Row>
                <Grid.Column>
                    <Button.Group floated='left'>
                        <Button basic onClick={buttonClickBackHandler}>{t('generic.back')}</Button>
                    </Button.Group>
                    <Button.Group floated='right'>
                        <Button size='huge' onClick={buttonClickHandler} disabled={nextStepDisabled} >{t('generic.next')}</Button>
                    </Button.Group>
                </Grid.Column>
            </Grid.Row>
        </Grid>
    </Segment>
}

export default Products;