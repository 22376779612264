import { Fragment } from "react";
import { Segment, Container } from "semantic-ui-react";
import AuthForm from "../components/auth/AuthForm";

const AuthPage = () => {
    return <Fragment>
        <AuthForm />
        <Segment>
            <Container textAlign='center'><p>Welkom op de demo pagina van 168. Deze login pagina is toegevoegd voor de pilot omgeving en zal op een andere wijze worden geimplementeerd na de pilot. Graag alleen inloggen na invite.</p></Container>
        </Segment>
    </Fragment>
}

export default AuthPage;